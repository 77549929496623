import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/xampp/htdocs/hausman/app/src/client/template.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnimateIntroBanner = makeShortcode("AnimateIntroBanner");
const RowLines = makeShortcode("RowLines");
const Decoration = makeShortcode("Decoration");
const Block = makeShortcode("Block");
const Heading = makeShortcode("Heading");
const LearnMore = makeShortcode("LearnMore");
const AnimateIntroHeadline = makeShortcode("AnimateIntroHeadline");
const AnimateIntroBlock = makeShortcode("AnimateIntroBlock");
const Paragraph = makeShortcode("Paragraph");
const Row = makeShortcode("Row");
const AnimateIntroWrapper = makeShortcode("AnimateIntroWrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnimateIntroBanner id="banner" className="background_image dark" background={<><RowLines mdxType="RowLines" /><Decoration from="/service-operations/banner.jpg" mdxType="Decoration" /></>} mdxType="AnimateIntroBanner">
  <Block mdxType="Block">
    <Heading mdxType="Heading">
      O&M: Operations and Maintenance
    </Heading>
    <LearnMore elementId="projects_list" color="white" mdxType="LearnMore" />
  </Block>
    </AnimateIntroBanner>
    <AnimateIntroHeadline id="headline" background={<RowLines mdxType="RowLines" />} mdxType="AnimateIntroHeadline">
  <Block mdxType="Block">
    <Heading mdxType="Heading">
      Efficiency-driven Delivery:
      No Interruption,
      No Downtime
    </Heading>
  </Block>
  <Block mdxType="Block">
    <Decoration from="/service-health/headline.jpg" mdxType="Decoration" />
  </Block>
  <AnimateIntroBlock mdxType="AnimateIntroBlock">
    <Paragraph mdxType="Paragraph">Hausman strives to provide turnkey solutions to its clients across various kinds of properties and assets, from real estate and shopping complexes to warehouses and industrial camp accommodations.</Paragraph>
    <Paragraph mdxType="Paragraph">For every project, our team aims for the highest quality standards and your complete satisfaction, while keeping an eye on safety, schedule, and cost-effectiveness. This is achieved through careful assessments & analyses, diligent applications, & strong communication and coordination channels between all entities.</Paragraph>
  </AnimateIntroBlock>  
    </AnimateIntroHeadline>
    <Row id="main_points" background={<RowLines mdxType="RowLines" />} mdxType="Row">
  <AnimateIntroBlock mdxType="AnimateIntroBlock">
    <Paragraph mdxType="Paragraph">We have developed a multi-layered approach to risk assessment for your facility assets and equipment, focused on business continuity at all times:</Paragraph>
  </AnimateIntroBlock>
  <Block className="content_block" mdxType="Block">
  <AnimateIntroWrapper className="point parallax_wrapper" mdxType="AnimateIntroWrapper">
  <Paragraph className="numbered" mdxType="Paragraph"><span className="parallax"><span className="number">1</span></span> First Observational Risk Assessment: A first visual assessment of your assets.</Paragraph>
  </AnimateIntroWrapper>
  <AnimateIntroWrapper className="point parallax_wrapper" mdxType="AnimateIntroWrapper">
  <Paragraph className="numbered" mdxType="Paragraph"><span className="parallax"><span className="number">2</span></span> Critical Engineering Risk Management: An in-depth electro-mechanical study.</Paragraph>
  </AnimateIntroWrapper>
  <AnimateIntroWrapper className="point parallax_wrapper" mdxType="AnimateIntroWrapper">
  <Paragraph className="numbered" mdxType="Paragraph"><span className="parallax"><span className="number">3</span></span> Single Point of Failure: An evaluation of every single piece of major equipment.</Paragraph>
  </AnimateIntroWrapper>
  <AnimateIntroWrapper className="point parallax_wrapper" mdxType="AnimateIntroWrapper">
  <Paragraph className="numbered" mdxType="Paragraph"><span className="parallax"><span className="number">4</span></span> Condition Survey: A detailed survey to benchmark and rate the condition of every asset.</Paragraph>
  </AnimateIntroWrapper>    
  </Block>
    </Row>
    <Row id="expertise" background={<RowLines mdxType="RowLines" />} mdxType="Row">
  <AnimateIntroBlock mdxType="AnimateIntroBlock">
    <Paragraph mdxType="Paragraph">Our dynamic approach throughout our Operations & Maintenance (O&M) activities means our team is always proactive in identifying and preventing potential risks, and you can benefit from a single point of contact while we deal with all other parties. </Paragraph><Paragraph mdxType="Paragraph">If you already have an in-house maintenance department that you would like to optimize, our team of experts can provide consultancy services & manage specialized trainings according to your needs. </Paragraph>
  </AnimateIntroBlock>
  <AnimateIntroBlock className="content_block" mdxType="AnimateIntroBlock">
  <Heading mdxType="Heading">Expertise</Heading>
  <ul className="intro_from_below">
    <li>Commissioning & Handing Over</li>
    <li>Facility Management Audit</li>
    <li>Asset Capturing & Register</li>
    <li>Condition Survey</li>
    <li>Critical Engineering Risk Management</li>
    <li>First Observational Risk Assessment</li>
    <li>Single Point of Failure</li>
    <li>Procurement<br /> and Subcontractor Management</li>
    <li>Inventory Management</li>
    <li>Preventive, Predictive & Corrective Maintenance</li>
    <li>Training & Skill Development</li>
    <li>Life Cycle Costing</li>
    <li>Budgeting</li>
    <li>Property Management</li>
    <li>Soft Services Management</li>
  </ul>    
  </AnimateIntroBlock>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      